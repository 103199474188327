<template>
  <div class="container">
    <header-back name='修改密码'></header-back>
    <section class="top44">
      <div class="user-cen">
        <van-form @submit="onSubmit">
          <van-field
            v-model="pwd"
            name="pwd"
            label=""
            placeholder="请输入原密码"
          />
          <van-field
            v-model="pwd1"
            name="pwd1"
            label=""
            placeholder="请输入新密码"
          />
          <van-field
            v-model="pwd2"
            name="pwd2"
            label=""
            placeholder="请再次输入新密码"
          />
          <div class="user-btn">
            <van-button
              round
              type="info"
              size="small"
              :color="variables.textColor"
              native-type="submit"
            >提交</van-button>
          </div>
        </van-form>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import variables from "@/styles/variables.scss";
import API from "../../api/http";
export default {
  components: { HeaderBack },
  data() {
    return {
      pwd: "",
      pwd1: "",
      pwd2: "",
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {},

  methods: {
    onSubmit(values) {
      if (!values.pwd) {
        Toast("请输入原密码");
        return;
      }
      if (!values.pwd1) {
        Toast("请输入新密码");
        return;
      }
      if (!values.pwd2) {
        Toast("请再次输入新密码");
        return;
      }
      if (values.pwd1 !== values.pwd2) {
        Toast("两次密码不一致");
        return;
      }
      API.getPaw({
        oldP: this.pwd,
        newP: this.pwd1,
        reNewP: this.pwd2,
      }).then((res) => {
        Toast("修改密码成功");
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 16px 15px;
  box-sizing: border-box;
}
.user-cen {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.user-btn {
  margin: 16px;
  text-align: center;
  .van-button {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
